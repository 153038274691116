import { PathologyPreparePrompt } from ".";

export default function PathologyPreparePromptExamples() {
  function onBackButtonClick() {
    console.log("Back button clicked");
  }

  return (
    <>
      <PathologyPreparePrompt onBackButtonClick={onBackButtonClick} />
    </>
  );
}
