import IconLoader from "@/features/Common/components/IconLoader";
import Panel from "@/features/Common/components/Panel";
import { MembershipDependent } from "@/services/core-api-adapter";
import { FaceIconBrand } from "@/theme/icons";
import { convertPxToRem } from "@/utils";
import { Typography, Stack, ListItem, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

interface DependentsListProps {
  dependentsList: MembershipDependent[];
  onEditButtonClick: (dependent: MembershipDependent) => void;
  onDeleteButtonClick: (dependent: MembershipDependent) => void;
  isError: boolean;
}

export default function DependentsList({
  dependentsList,
  onEditButtonClick,
  onDeleteButtonClick,
  isError,
}: DependentsListProps) {
  const { t } = useTranslation();
  return (
    <>
      <Stack
        component="ul"
        aria-label={t("MyProfile.dependants.list.label")}
        spacing={2}
      >
        {dependentsList.length > 0 && !isError && (
          <>
            {(dependentsList || []).map((dependent, index) => {
              const fullName = `${dependent?.firstName} ${dependent?.lastName}`;
              const listItemKey = `${fullName}_${index}`;
              const dateOfBirth = dependent?.dateOfBirth;

              return (
                <Panel key={listItemKey}>
                  <ListItem sx={{ padding: 0 }} aria-label={fullName}>
                    <Stack spacing={2} direction="row" width="100%">
                      <FaceIconBrand
                        sx={{ fontSize: (theme) => theme.spacing(4) }}
                      />

                      <Stack width="100%">
                        <Stack spacing={1}>
                          <Stack
                            spacing={1}
                            direction="row"
                            alignContent="flex-start"
                            justifyContent="space-between"
                          >
                            <Typography
                              component="p"
                              variant="h4"
                              fontWeight="600"
                              sx={{ wordBreak: "break-word" }}
                            >
                              {fullName}
                            </Typography>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="flex-end"
                            >
                              <IconButton
                                onClick={() => onEditButtonClick(dependent)}
                                color="primary"
                                aria-label={t("common.editButton")}
                              >
                                <IconLoader icon="PenIcon" />
                              </IconButton>

                              <IconButton
                                onClick={() => onDeleteButtonClick(dependent)}
                                color="primary"
                                aria-label={t("common.removeButton")}
                              >
                                <IconLoader icon="BinIcon" />
                              </IconButton>
                            </Stack>
                          </Stack>
                        </Stack>
                        <Stack>
                          <Typography
                            sx={{
                              fontSize: convertPxToRem(10),
                              fontWeight: "bold",
                              color: "neutral.500",
                            }}
                          >
                            {t("DateOfBirthInput.label")}
                          </Typography>
                          <Typography variant="body2">{dateOfBirth}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </ListItem>
                </Panel>
              );
            })}
          </>
        )}

        {dependentsList.length < 1 && !isError && (
          <Panel>
            <ListItem
              sx={{ padding: 0 }}
              aria-label={t("MyProfile.dependants.noDependants")}
            >
              <Stack
                spacing={2}
                direction="row"
                width="100%"
                alignItems="center"
              >
                <FaceIconBrand sx={{ fontSize: (theme) => theme.spacing(4) }} />

                <Typography
                  component="p"
                  variant="h4"
                  fontWeight="600"
                  sx={{ wordBreak: "break-word" }}
                >
                  {t("MyProfile.dependants.noDependants")}
                </Typography>
              </Stack>
            </ListItem>
          </Panel>
        )}

        {isError && (
          <Panel>
            <Typography variant="h4" align="center">
              {t("common.somethingWentWrong")}
            </Typography>
          </Panel>
        )}
      </Stack>
    </>
  );
}
