import { maxWidthConfig } from "@/theme";
import { useEffect } from "react";
import UnsubscribeFlow from "../../components/UnsubscribeFlow";

export default function UnsubscribePage({
  changeMaxWidth,
}: {
  changeMaxWidth: (arg: string) => void;
}) {
  useEffect(() => {
    changeMaxWidth(maxWidthConfig.playgroundMaxWidth);
    return () => {
      changeMaxWidth(maxWidthConfig.defaultMaxWidth);
    };
  }, []);

  return <UnsubscribeFlow />;
}
