import { IdentityDocumentType } from "@/services/core-api-adapter";
import MyProfile from ".";
import { Divider, Stack, Typography } from "@mui/material";

export default function MyProfileExamples() {
  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        <Typography variant="h1">Has dependants</Typography>
        <MyProfile
          onEditChildDependentButtonClick={() => {}}
          onAddChildDependentButtonClick={() => {}}
          onDeleteChildDependentButtonClick={() => {}}
          isError={false}
          dependentsList={[
            {
              firstName: "Nuki",
              lastName: "Persona",
              identityDocumentType: IdentityDocumentType.IdNumber,
              identityDocumentValue: "9603124800089",
              sexAtBirth: "female",
              dateOfBirth: "1996-03-12",
              identityDocumentCountryOfIssue: "ZAF",
            },
            {
              firstName: "Nuki",
              lastName: "Test",
              identityDocumentType: null,
              identityDocumentValue: null,
              sexAtBirth: "female",
              dateOfBirth: "1996-03-12",
              identityDocumentCountryOfIssue: "ZAF",
            },
          ]}
          remainingDependentsCount={2}
          maxDependentAge={18}
        />
      </Stack>
      <Divider />
      <Stack>
        <Typography variant="h1">No dependants</Typography>
        <MyProfile
          onEditChildDependentButtonClick={() => {}}
          onAddChildDependentButtonClick={() => {}}
          onDeleteChildDependentButtonClick={() => {}}
          isError={false}
          dependentsList={[]}
          remainingDependentsCount={2}
          maxDependentAge={18}
        />
      </Stack>

      <Divider />
      <Stack>
        <Typography variant="h1">Has error</Typography>
        <MyProfile
          onEditChildDependentButtonClick={() => {}}
          onAddChildDependentButtonClick={() => {}}
          onDeleteChildDependentButtonClick={() => {}}
          isError={true}
          dependentsList={[]}
          remainingDependentsCount={2}
          maxDependentAge={18}
        />
      </Stack>
    </Stack>
  );
}
