import { Stack } from "@mui/material";
import WhatsappBreakout from ".";

export default function WhatsappBreakoutExamples() {
  return (
    <Stack height="100vh">
      <WhatsappBreakout
        url="https://wa.me/254722222222"
        provider="Ampath"
        logo="/assets/MedicalAssistancePartners/Ampath/logo-color.svg"
        onBackButtonClick={() => {
          console.log("onBackButtonClick");
        }}
      />
    </Stack>
  );
}
