import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";
import CircledBackButton from "@/features/Common/components/CircledBackButton";
import DateOfBirthInput, {
  DOBFormData,
} from "@/features/Common/components/DateOfBirthInput";
import FormInputErrorList from "@/features/Common/components/FormInputErrorList";
import FullscreenLoadingIndicator from "@/features/Common/components/FullscreenLoadingIndicator";
import {
  AnalyticsEvent,
  trackFormEvent,
  trackPageViewV2,
} from "@/services/analytics-adapter";
import { MembershipDependent } from "@/services/core-api-adapter";
import { styleInputDefault } from "@/theme";
import { convertPxToRem } from "@/utils";
import { isValid } from "@/utils/validators/common";
import {
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useId, useState } from "react";
import { useTranslation } from "react-i18next";

interface AddDependentsFormProps {
  onCompletedFlow?: (dependents: MembershipDependent) => void;
  selectedDependent?: MembershipDependent | null;
  onBackButtonClick?: () => void;
}

export default function AddDependentsForm({
  onCompletedFlow,
  selectedDependent,
  onBackButtonClick,
}: AddDependentsFormProps) {
  const { t } = useTranslation();
  const firstNameInputID = useId();
  const lastNameInputID = useId();
  const sexAtBirthInputID = useId();
  const maxDependentAge = 18;

  const [firstName, setFirstName] = useState(
    selectedDependent?.firstName || ""
  );
  const [lastName, setLastName] = useState(selectedDependent?.lastName || "");

  const [dateOfBirth, setDateOfBirth] = useState(
    selectedDependent?.dateOfBirth || ""
  );
  const [isValidDateOfBirth, setIsValidDateOfBirth] = useState(false);

  const [sexAtBirth, setSexAtBirth] = useState(
    transformSexAtBirthToExpectedValue(selectedDependent?.sexAtBirth || "M") ||
      "Male"
  );
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const [isFormError, setIsFormError] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  function transformSexAtBirthToExpectedValue(value: string) {
    switch (value.toLowerCase()) {
      case "m":
        return "Male";
      case "f":
        return "Female";
      default:
        return value;
    }
  }

  function updateSubmitButtonEnabledState(value: any) {
    setIsSubmitButtonEnabled(isValid(value));
  }

  useEffect(() => {
    updateSubmitButtonEnabledState(
      isValidDateOfBirth && firstName && lastName && dateOfBirth && sexAtBirth
    );
  }, [firstName, lastName, dateOfBirth, sexAtBirth, isValidDateOfBirth]);

  function onFirstNameInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setFirstName(value);
  }

  function onLastNameInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setLastName(value);
  }

  function onDateOfBirthInput(
    data: DOBFormData,
    validatedTotalDate: boolean
  ): void {
    if (validatedTotalDate) {
      const dateOfBirth = `${data.year}-${data.month.padStart(
        2,
        "0"
      )}-${data.day.padStart(2, "0")}`;
      setDateOfBirth(dateOfBirth);
    } else {
      setDateOfBirth("");
    }
  }

  function dateOfBirthValidation(dateOfBirth: string): boolean {
    const currentDate = new Date();
    const _dateOfBirth = new Date(dateOfBirth);
    let age = currentDate.getFullYear() - _dateOfBirth.getFullYear();
    if (
      currentDate.getMonth() < _dateOfBirth.getMonth() ||
      (currentDate.getMonth() === _dateOfBirth.getMonth() &&
        currentDate.getDate() < _dateOfBirth.getDate())
    ) {
      age--;
    }
    if (age > maxDependentAge - 1) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    setIsValidDateOfBirth(dateOfBirthValidation(dateOfBirth));
  }, [dateOfBirth, isValidDateOfBirth]);

  function onSexAtBirthChange(el: any) {
    setSexAtBirth(el.target.value);
  }

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsSubmittingForm(true);

    const getSelectedDependentData = selectedDependent || {};

    const transformSexAtBirthValue =
      sexAtBirth?.toLowerCase() === "male" ? "M" : "F";

    const formData = {
      ...getSelectedDependentData,
      firstName,
      lastName,
      dateOfBirth,
      sexAtBirth: transformSexAtBirthValue,
    };

    onDone(formData);

    trackFormEvent(AnalyticsEvent.FORM_COMPLETE, {
      formName: "Add dependents",
    });
  }

  function onDone(formData: MembershipDependent) {
    if (onCompletedFlow) {
      setIsFormError(false);
      onCompletedFlow(formData);
    }
  }

  useEffect(() => {
    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Add dependents",
    });

    trackPageViewV2({
      pageName: "My profile:Add dependents",
      pageSubSection1: "My profile",
      pageSubSection2: "My profile:Add dependents",
      pageCategory: "My profile",
    });
  }, []);

  return (
    <>
      <Stack justifyContent="flex-end">
        {isSubmittingForm ? (
          <FullscreenLoadingIndicator />
        ) : (
          <Stack spacing={4}>
            <CircledBackButton showLabel={true} onClick={onBackButtonClick} />

            <form onSubmit={onSubmit}>
              <Stack spacing={2} justifyContent="flex-end" sx={{ pb: 2 }}>
                <Stack spacing={2}>
                  <Typography component="h1" variant="h3">
                    {t("MyProfile.addDependantsForm.title")}
                  </Typography>
                </Stack>
                <Stack spacing={1}>
                  <InputLabel htmlFor={firstNameInputID}>
                    <Typography
                      color="neutral.700"
                      variant="body2"
                      fontWeight={300}
                    >
                      {t("FirstNameInput.label")}
                    </Typography>
                  </InputLabel>
                  <TextField
                    id={firstNameInputID}
                    value={firstName}
                    size="small"
                    onInput={onFirstNameInput}
                    inputProps={{
                      autoComplete: "off",
                      "aria-label": t("FirstNameInput.label"),
                    }}
                    sx={{
                      input: {
                        ...styleInputDefault,
                        fontSize: convertPxToRem(16),
                      },
                    }}
                    fullWidth
                  />
                </Stack>
                <Stack spacing={1}>
                  <InputLabel htmlFor={lastNameInputID}>
                    <Typography
                      color="neutral.700"
                      variant="body2"
                      fontWeight={300}
                    >
                      {t("LastNameInput.label")}
                    </Typography>
                  </InputLabel>
                  <TextField
                    id={lastNameInputID}
                    value={lastName}
                    size="small"
                    onInput={onLastNameInput}
                    inputProps={{
                      autoComplete: "off",
                      "aria-label": t("LastNameInput.label"),
                    }}
                    sx={{
                      input: {
                        ...styleInputDefault,
                        fontSize: convertPxToRem(16),
                      },
                    }}
                    fullWidth
                  />
                </Stack>

                <Stack spacing={1}>
                  <InputLabel>
                    <Typography
                      color="neutral.700"
                      variant="body2"
                      fontWeight={300}
                    >
                      {t("DateOfBirthInput.label")}
                    </Typography>
                  </InputLabel>
                  <DateOfBirthInput
                    value={dateOfBirth}
                    onChange={onDateOfBirthInput}
                    placeholder={{
                      day: "DD",
                      month: "MM",
                      year: "YYYY",
                    }}
                    showLabel={false}
                    isError={!isValidDateOfBirth}
                    errorText={t(
                      "MembershipAddDependentsForm.DateOfBirthError",
                      {
                        maxDependentAge: maxDependentAge,
                      }
                    )}
                  />
                </Stack>
                <Stack spacing={1}>
                  <InputLabel>
                    <Typography
                      color="neutral.700"
                      variant="body2"
                      fontWeight={300}
                    >
                      {t("SexAtBirthInput.label")}
                    </Typography>
                  </InputLabel>
                  <Select
                    id={sexAtBirthInputID}
                    value={sexAtBirth}
                    onChange={onSexAtBirthChange}
                    inputProps={{
                      "aria-label": t("SexAtBirthInput.label"),
                    }}
                    MenuProps={{
                      "aria-label": t("SexAtBirthInput.label"),
                    }}
                  >
                    <MenuItem value={"Male"}>
                      <Stack textAlign={"left"}>{t("common.male")}</Stack>
                    </MenuItem>
                    <MenuItem value={"Female"}>
                      <Stack textAlign={"left"}>{t("common.female")}</Stack>
                    </MenuItem>
                  </Select>
                </Stack>

                {isFormError && (
                  <FormInputErrorList
                    errors={[t("common.somethingWentWrong")]}
                  />
                )}
                <Stack flexDirection="row" gap={2}>
                  <ButtonWithAnalytics
                    intent="confirmational"
                    text={t("common.cancelButton")}
                    page="My profile"
                    variant="outlined"
                    color="primary"
                    aria-label={t("common.cancelButton")}
                    onClick={onBackButtonClick}
                    fullWidth
                  >
                    {t("common.cancelButton")}
                  </ButtonWithAnalytics>

                  <ButtonWithAnalytics
                    intent="confirmational"
                    text={t("common.saveButton")}
                    page="My profile"
                    type="submit"
                    color="primary"
                    disabled={!isSubmitButtonEnabled}
                    aria-label={t("common.saveButton")}
                    fullWidth
                  >
                    {t("common.saveButton")}
                  </ButtonWithAnalytics>
                </Stack>
              </Stack>
            </form>
          </Stack>
        )}
      </Stack>
    </>
  );
}
