import DefaultAccordion from "@/features/Common/components/DefaultAccordion";
import IconLoader from "@/features/Common/components/IconLoader";
import { trackEvent } from "@/services/analytics-adapter";
import { PathologyPanel } from "@/services/core-api-adapter";
import { convertPxToRem } from "@/utils";
import { Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

interface PanelScreeningInfoAccordionProps {
  panelData: PathologyPanel;
  icon?: string;
  screeningInfoType: string;
}

export default function PanelScreeningInfoAccordion({
  panelData,
  icon,
  screeningInfoType,
}: PanelScreeningInfoAccordionProps) {
  const { t, i18n } = useTranslation();

  function onAccordionExpand(expanded: boolean) {
    trackEvent({
      event: "action.pathologyPanelInfoAccordionClicked",
      source: "Pathology panel details",
      type: screeningInfoType,
      panelName: panelData?.panelName,
      expanded: expanded,
    });
  }

  return (
    <DefaultAccordion
      title={`PathologyPanelDetails.${screeningInfoType}.${panelData?.panelChargeCode}.title`}
      icon={
        <IconLoader
          icon={icon || "HeartInCircleIcon"}
          sx={{ color: "accent2.dark" }}
          fontSize="large"
        />
      }
      onExpand={onAccordionExpand}
    >
      <Stack spacing={2}>
        <Trans>
          <Typography variant="body1" sx={{ color: "neutral.main" }}>
            {t(
              `PathologyPanelDetails.${screeningInfoType}.${panelData?.panelChargeCode}.body` as any
            )}
          </Typography>
        </Trans>

        {i18n.exists(
          `PathologyPanelDetails.${screeningInfoType}.${panelData?.panelChargeCode}.body.items`
        ) && (
          <Stack
            component="ul"
            spacing={1}
            sx={{ paddingLeft: convertPxToRem(16) }}
          >
            {(
              t<any, any, string[]>(
                `PathologyPanelDetails.${screeningInfoType}.${panelData?.panelChargeCode}.body.items`,
                {
                  returnObjects: true,
                }
              ) as []
            ).map((text: string, index: number) => (
              <Trans key={String(text + index)}>
                <Typography variant="body1" component="li" color="neutral">
                  {text}
                </Typography>
              </Trans>
            ))}
          </Stack>
        )}
      </Stack>
    </DefaultAccordion>
  );
}
