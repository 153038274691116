import { Divider, Grid, Stack, Typography } from "@mui/material";
import ItemPill from ".";
import {
  DirectToDoctorPrimarySymptom,
  DirectToDoctorPrimarySymptomOption,
  Status,
  PathologyPanel,
  PathologyPanelChargeCode,
} from "@/services/core-api-adapter";
import { convertPxToRem } from "@/utils";
import IconLoader from "../IconLoader";

const primarySymptoms: DirectToDoctorPrimarySymptom[] = [
  {
    id: "ee15c3f0-a2fc-4c9c-8a80-2c60fcf77823",
    name: DirectToDoctorPrimarySymptomOption.SKIN_CONDITIONS,
    friendlyName: "SKIN_CONDITIONS",
    status: Status.ACTIVE,
  },
  {
    id: "d24afc9c-03b2-474c-b710-0b46e57b4bc1",
    name: DirectToDoctorPrimarySymptomOption.EYE_INFECTIONS,
    friendlyName: "EYE_INFECTIONS",
    status: Status.ACTIVE,
  },
  {
    id: "f5bc7cfd-9283-4033-bcd1-842dae372530",
    name: DirectToDoctorPrimarySymptomOption.BLADDER_INFECTION,
    friendlyName: "BLADDER_INFECTION",
    status: Status.ACTIVE,
  },
  {
    id: "dae0c139-2fac-44a0-bdff-a8f3b0624d36",
    name: DirectToDoctorPrimarySymptomOption.COLD_FLU,
    friendlyName: "COLD_FLU",
    status: Status.ACTIVE,
  },
  {
    id: "7697221a-5e92-4a13-8a77-40df6cf5a9a7",
    name: DirectToDoctorPrimarySymptomOption.VOMITING_DIARRHEA,
    friendlyName: "VOMITING_DIARRHEA",
    status: Status.ACTIVE,
  },
  {
    id: "896c7a91-5ef5-49fe-b3f4-4eacaa91b206",
    name: DirectToDoctorPrimarySymptomOption.HEADACHE,
    friendlyName: "HEADACHE",
    status: Status.ACTIVE,
  },
  {
    id: "fa2dfc8f-8727-446f-a063-556d16e737db",
    name: DirectToDoctorPrimarySymptomOption.BACK_PAIN,
    friendlyName: "BACK_PAIN",
    status: Status.ACTIVE,
  },
  {
    id: "554bb6f0-88b1-45d2-ba84-f418c57c5bba",
    name: DirectToDoctorPrimarySymptomOption.CONTRACEPTION,
    friendlyName: "CONTRACEPTION",
    status: Status.ACTIVE,
  },
  {
    id: "ecd372e4-2a23-44e4-a4e2-2ee556cc8020",
    name: DirectToDoctorPrimarySymptomOption.GENITAL_INFECTIONS,
    friendlyName: "GENITAL_INFECTIONS",
    status: Status.ACTIVE,
  },
  {
    id: "4b00a459-72e5-4c7e-bd46-3d63e129078b",
    name: DirectToDoctorPrimarySymptomOption.GENERAL_CONDITIONS,
    friendlyName: "GENERAL_CONDITIONS",
    status: Status.ACTIVE,
  },
];

const pathologyPanels: PathologyPanel[] = [
  {
    panelId: "1",
    panelName: "Std Screening (excl HIV)",
    amountTotal: 0.0,
    amountTotalFormatted: "R200",
    panelChargeCode: PathologyPanelChargeCode.STD_SCREENING_INCL_HIV,
    panelDescription:
      "This is the initial description of the Panel STD Screening (excl HIV)",
    status: Status.ACTIVE,
    orderIndex: 6,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      { testName: "STD PCR", testAmount: 0.0, testCode: "1234" },
      {
        testName: "Hep B surface antigen",
        testAmount: 0.0,
        testCode: "1234",
      },
      {
        testName: "Syphilis RPR + T Pallidum ELISA",
        testAmount: 0.0,
        testCode: "1234",
      },
    ],
  },
  {
    panelId: "1",
    panelName: "Syphilis",
    amountTotal: 0.0,
    amountTotalFormatted: "R200",
    panelChargeCode: PathologyPanelChargeCode.SYPHILIS,
    panelDescription: "This is the initial description of the Panel Syphilis",
    status: Status.ACTIVE,
    orderIndex: 8,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "Syphilis RPR + T Pallidum ELISA",
        testAmount: 0.0,
        testCode: "1234",
      },
    ],
  },
  {
    panelId: "1",
    panelName: "STD PCR",
    amountTotal: 0.0,
    amountTotalFormatted: "R200",
    panelChargeCode: PathologyPanelChargeCode.STD_PCR,
    panelDescription: "This is the initial description of the Panel STD PCR",
    status: Status.ACTIVE,
    orderIndex: 7,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      { testName: "STD PCR", testAmount: 0.0, testCode: "1234" },
    ],
  },
  {
    panelId: "1",
    panelName: "HIV Screening",
    amountTotal: 0.0,
    amountTotalFormatted: "R200",
    panelChargeCode: PathologyPanelChargeCode.HIV_SCREENING,
    panelDescription:
      "This is the initial description of the Panel HIV Screening",
    status: Status.ACTIVE,
    orderIndex: 4,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      { testName: "HIV Elisa", testAmount: 0.0, testCode: "1234" },
    ],
  },
  {
    panelId: "1",
    panelName: "Hepatitis B",
    amountTotal: 0.0,
    amountTotalFormatted: "R200",
    panelChargeCode: PathologyPanelChargeCode.HEPATITIS_B,
    panelDescription:
      "This is the initial description of the Panel Hepatitis B",
    status: Status.ACTIVE,
    orderIndex: 9,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "Hep B surface antigen",
        testAmount: 0.0,
        testCode: "1234",
      },
    ],
  },
  {
    panelId: "1",
    panelName: "Male over 45",
    amountTotal: 0.0,
    amountTotalFormatted: "R200",
    panelChargeCode: PathologyPanelChargeCode.MALE_OVER_45,
    panelDescription:
      "This is the initial description of the Panel Male Over 45",
    status: Status.ACTIVE,
    orderIndex: 3,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      { testName: "PSA", testAmount: 0.0, testCode: "1234" },
      {
        testName: "Fasting glucose",
        testAmount: 0.0,
        testCode: "1234",
      },
      { testName: "Haemoglobin", testAmount: 0.0, testCode: "1234" },
      { testName: "ALT", testAmount: 0.0, testCode: "1234" },
      { testName: "Creatine", testAmount: 0.0, testCode: "1234" },
      { testName: "TSH", testAmount: 0.0, testCode: "1234" },
      { testName: "LDL", testAmount: 0.0, testCode: "1234" },
    ],
  },
  {
    panelId: "1",
    panelName: "Anaemia",
    amountTotal: 0.0,
    amountTotalFormatted: "R200",
    panelChargeCode: PathologyPanelChargeCode.ANAEMIA,
    panelDescription: "This is the initial description of the Panel Aneamia",
    status: Status.ACTIVE,
    orderIndex: 10,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      { testName: "FBC", testAmount: 0.0, testCode: "1234" },
      { testName: "Ferritin", testAmount: 0.0, testCode: "1234" },
    ],
  },
  {
    panelId: "1",
    panelName: "Male under 45",
    amountTotal: 0.0,
    amountTotalFormatted: "R200",
    panelChargeCode: PathologyPanelChargeCode.MALE_UNDER_45,
    panelDescription:
      "This is the initial description of the Panel Male Under 45",
    status: Status.ACTIVE,
    orderIndex: 2,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      { testName: "LDL", testAmount: 0.0, testCode: "1234" },
      { testName: "TSH", testAmount: 0.0, testCode: "1234" },
      {
        testName: "Fasting glucose",
        testAmount: 0.0,
        testCode: "1234",
      },
      { testName: "Haemoglobin", testAmount: 0.0, testCode: "1234" },
      { testName: "Creatine", testAmount: 0.0, testCode: "1234" },
      { testName: "ALT", testAmount: 0.0, testCode: "1234" },
    ],
  },
  {
    panelId: "1",
    panelName: "HIV Monitoring",
    amountTotal: 0.0,
    amountTotalFormatted: "R200",
    panelChargeCode: PathologyPanelChargeCode.HIV_MONITORING,
    panelDescription:
      "This is the initial description of the Panel HIV Monitoring",
    status: Status.ACTIVE,
    orderIndex: 11,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      { testName: "Creatine", testAmount: 0.0, testCode: "1234" },
      { testName: "CD4", testAmount: 0.0, testCode: "1234" },
      {
        testName: "HIV VIRAL LOAD",
        testAmount: 0.0,
        testCode: "1234",
      },
      { testName: "ALT", testAmount: 0.0, testCode: "1234" },
      { testName: "Haemoglobin", testAmount: 0.0, testCode: "1234" },
    ],
  },
  {
    panelId: "1",
    panelName: "STD Screening (incl HIV)",
    amountTotal: 0.0,
    amountTotalFormatted: "R200",
    panelChargeCode: PathologyPanelChargeCode.STD_SCREENING_INCL_HIV,
    panelDescription:
      "This is the initial description of the Panel STD Screening (incl HIV)",
    status: Status.ACTIVE,
    orderIndex: 5,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      { testName: "STD PCR", testAmount: 0.0, testCode: "1234" },
      { testName: "HIV Elisa", testAmount: 0.0, testCode: "1234" },
      {
        testName: "Syphilis RPR + T Pallidum ELISA",
        testAmount: 0.0,
        testCode: "1234",
      },
      {
        testName: "Hep B surface antigen",
        testAmount: 0.0,
        testCode: "1234",
      },
    ],
  },
  {
    panelId: "1",
    panelName: "Female",
    amountTotal: 0.0,
    amountTotalFormatted: "R200",
    panelChargeCode: PathologyPanelChargeCode.FEMALE,
    panelDescription: "This is the initial description of the Panel Female",
    status: Status.ACTIVE,
    orderIndex: 1,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "Fasting glucose",
        testAmount: 0.0,
        testCode: "1234",
      },
      { testName: "Haemoglobin", testAmount: 0.0, testCode: "1234" },
      { testName: "TSH", testAmount: 0.0, testCode: "1234" },
      { testName: "LDL", testAmount: 0.0, testCode: "1234" },
      { testName: "ALT", testAmount: 0.0, testCode: "1234" },
      { testName: "Creatine", testAmount: 0.0, testCode: "1234" },
    ],
  },
];

interface dataMap {
  [key: string]: any;
}

const iconStyles = {
  width: convertPxToRem(36),
  height: convertPxToRem(36),
};

const primarySymptomPillMap: dataMap = {
  SKIN_CONDITIONS: {
    title: "DirectToDoctor.primarySymptoms.skinConditions.title",
    subtitle: "DirectToDoctor.primarySymptoms.skinConditions.subtitle",
    icon: <IconLoader icon="SkinConditionsIcon" sx={iconStyles} />,
  },
  EYE_INFECTIONS: {
    title: "DirectToDoctor.primarySymptoms.eyeInfection.title",
    subtitle: "DirectToDoctor.primarySymptoms.eyeInfection.subtitle",
    icon: <IconLoader icon="EyeInfectionIcon" sx={iconStyles} />,
  },
  BLADDER_INFECTION: {
    title: "DirectToDoctor.primarySymptoms.bladderInfection.title",
    subtitle: "DirectToDoctor.primarySymptoms.bladderInfection.subtitle",
    icon: <IconLoader icon="BladderInfectionIcon" sx={iconStyles} />,
  },
  COLD_FLU: {
    title: "DirectToDoctor.primarySymptoms.coldFlu.title",
    subtitle: "DirectToDoctor.primarySymptoms.coldFlu.subtitle",
    icon: <IconLoader icon="ColdAndFluIcon" sx={iconStyles} />,
  },
  VOMITING_DIARRHEA: {
    title: "DirectToDoctor.primarySymptoms.vomitingDiarrhoea.title",
    subtitle: "DirectToDoctor.primarySymptoms.vomitingDiarrhoea.subtitle",
    icon: <IconLoader icon="VomitingAndDiarrhoeaIcon" sx={iconStyles} />,
  },
  HEADACHE: {
    title: "DirectToDoctor.primarySymptoms.headache.title",
    subtitle: "DirectToDoctor.primarySymptoms.headache.subtitle",
    icon: <IconLoader icon="HeadacheIcon" sx={iconStyles} />,
  },
  BACK_PAIN: {
    title: "DirectToDoctor.primarySymptoms.backPain.title",
    subtitle: "DirectToDoctor.primarySymptoms.backPain.subtitle",
    icon: <IconLoader icon="BackPainIcon" sx={iconStyles} />,
  },
  CONTRACEPTION: {
    title: "DirectToDoctor.primarySymptoms.contraception.title",
    subtitle: "DirectToDoctor.primarySymptoms.contraception.subtitle",
    icon: <IconLoader icon="ContraceptionIcon" sx={iconStyles} />,
  },
  GENITAL_INFECTIONS: {
    title: "DirectToDoctor.primarySymptoms.genitalInfections.title",
    subtitle: "DirectToDoctor.primarySymptoms.genitalInfections.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
  GENERAL_CONDITIONS: {
    title: "DirectToDoctor.primarySymptoms.generalConditions.title",
    subtitle: "DirectToDoctor.primarySymptoms.generalConditions.subtitle",
    icon: <IconLoader icon="GeneralDoctorConsultIcon" sx={iconStyles} />,
  },
};

const pathologyPanelPillMap: { [key: string]: any } = {
  "Male Under 45": {
    title: "PathologyPanel.maleUnder45.title",
    subtitle: "PathologyPanel.maleUnder45.subtitle",
    icon: <IconLoader icon="MaleSexIcon" sx={iconStyles} />,
  },
  "Male Over 45": {
    title: "PathologyPanel.maleOver45.title",
    subtitle: "PathologyPanel.maleOver45.subtitle",
    icon: <IconLoader icon="MaleSexIcon" sx={iconStyles} />,
  },
  Female: {
    title: "PathologyPanel.female.title",
    subtitle: "PathologyPanel.female.subtitle",
    icon: <IconLoader icon="FemaleSexIcon" sx={iconStyles} />,
  },
  "HIV Screening": {
    title: "PathologyPanel.hivScreening.title",
    subtitle: "PathologyPanel.hivScreening.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
  "HIV Monitoring": {
    title: "PathologyPanel.hivMonitoring.title",
    subtitle: "PathologyPanel.hivMonitoring.subtitle",
    icon: <IconLoader icon="VomitingAndDiarrhoeaIcon" sx={iconStyles} />,
  },
  Anaemia: {
    title: "PathologyPanel.anaemia.title",
    subtitle: "PathologyPanel.anaemia.subtitle",
    icon: <IconLoader icon="DropIcon" sx={iconStyles} />,
  },
  "STD Screening (incl HIV)": {
    title: "PathologyPanel.stdScreeningInclHiv.title",
    subtitle: "PathologyPanel.stdScreeningInclHiv.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
  "STD Screening (excl HIV)": {
    title: "PathologyPanel.stdScreeningExlHiv.title",
    subtitle: "PathologyPanel.stdScreeningExlHiv.subtitle",
    icon: <IconLoader icon="ContraceptionIcon" sx={iconStyles} />,
  },
  Syphilis: {
    title: "PathologyPanel.syphilis.title",
    subtitle: "PathologyPanel.syphilis.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
  "Hepatitis B": {
    title: "PathologyPanel.hepatitisB.title",
    subtitle: "PathologyPanel.hepatitisB.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
  "STD PCR": {
    title: "PathologyPanel.stdPcr.title",
    subtitle: "PathologyPanel.stdPcr.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
};

export default function ItemPillExamples() {
  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Typography variant="h2">Direct to Doctor</Typography>
        <Grid container spacing={2} rowSpacing={1}>
          {primarySymptoms.map((primarySymptom) => (
            <Grid key={primarySymptom.id} item>
              <ItemPill
                onClick={() => console.log("onClick")}
                selectedItem={primarySymptom}
                itemMap={primarySymptomPillMap}
                titleStyles={{ fontSize: convertPxToRem(16), fontWeight: 600 }}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
      <Divider />
      <Stack spacing={1}>
        <Typography variant="h2">Pathology Panels</Typography>
        <Grid container spacing={2} rowSpacing={1}>
          {pathologyPanels.map((pathologyPanel, index) => (
            <Grid key={pathologyPanel.panelName + index} item>
              <ItemPill
                onClick={() => console.log("onClick")}
                selectedItem={pathologyPanel}
                itemMap={pathologyPanelPillMap}
                titleStyles={{ fontSize: convertPxToRem(16), fontWeight: 600 }}
                shouldShowPrice
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
}
