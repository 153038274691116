import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";
import IconLoader from "@/features/Common/components/IconLoader";
import { trackPageViewV2 } from "@/services/analytics-adapter";
import theme from "@/theme";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface UnsubscribeSuccessProps {
  onDoneClick: () => void;
}

export default function UnsubscribeSuccess({
  onDoneClick,
}: UnsubscribeSuccessProps) {
  const { t } = useTranslation();

  useEffect(() => {
    trackPageViewV2({
      pageName: "Unsubscribe:Unsubscribe Success",
      pageSubSection1: "Unsubscribe",
      pageSubSection2: "Unsubscribe:Unsubscribe Success",
      pageCategory: "Unsubscribe",
    });
  }, []);

  return (
    <Stack
      p={2}
      mt={3}
      spacing={6}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <Stack spacing={2}>
        <IconLoader
          icon="FrowningFaceIcon"
          color="secondary"
          sx={{
            fontSize: theme.spacing(10),
            alignSelf: "center",
          }}
        />
        <Typography variant="h2">{t("Unsubscribe.success.title")}</Typography>
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h4" fontWeight={100}>
          {t("Unsubscribe.success.body")}
        </Typography>
      </Stack>

      <Box>
        <ButtonWithAnalytics
          text={t("Unsubscribe.success.CTA.title")}
          page="UnsubscribeSuccess"
          intent="confirmational"
          onClick={onDoneClick}
          endIcon={<IconLoader icon="ChevronNextIcon" />}
        >
          {t("Unsubscribe.success.CTA.title")}
        </ButtonWithAnalytics>
      </Box>
    </Stack>
  );
}
