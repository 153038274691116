import { PathologyPrompt } from ".";

export default function PathologyPromptExamples() {
  return (
    <PathologyPrompt
      amountTotalFormatted={"R134"}
      onContinueButtonClick={() => console.log("onContinueButtonClick")}
      onBackButtonClick={() => console.log("onBackButtonClick")}
    />
  );
}
