import { Stack } from "@mui/material";
import Breakout from ".";

export default function BreakoutExamples() {
  return (
    <Stack sx={{ height: "98vh" }}>
      <Breakout
        type="Intercare"
        redirectUrl="http://google.com"
        onConfirm={() => console.log("onConfirm")}
        onDecline={() => console.log("onDecline")}
      />
      <Breakout
        type="Ecentric"
        redirectUrl="http://google.com"
        onConfirm={() => console.log("onConfirm")}
        onDecline={() => console.log("onDecline")}
      />
    </Stack>
  );
}
