import DefaultAccordion from "@/features/Common/components/DefaultAccordion";
import IconLoader from "@/features/Common/components/IconLoader";
import { PathologyPanel } from "@/services/core-api-adapter";
import { convertPxToRem } from "@/utils";
import {
  Stack,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface PathologyRiskInfoAccordionProps {
  panelData: PathologyPanel;
}

export default function PathologyRiskInfoAccordion({
  panelData,
}: PathologyRiskInfoAccordionProps) {
  const { t } = useTranslation();

  const riskList = t<any, any, string[]>(
    `PathologyPanelDetails.whoIsAtRisk.${panelData?.panelChargeCode}.body.items`,
    {
      returnObjects: true,
    }
  ) as [];

  return (
    <DefaultAccordion
      title={`PathologyPanelDetails.whoIsAtRisk.title`}
      icon={
        <IconLoader
          icon="UserCircleIcon"
          sx={{ color: "accent2.dark" }}
          fontSize="large"
        />
      }
    >
      <Stack spacing={2}>
        <Stack component="ul" spacing={1}>
          {riskList &&
            riskList.length &&
            Array.isArray(riskList) &&
            riskList.map((text: string, index: number) => (
              <>
                <ListItem
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 1,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <IconLoader
                      icon="CheckmarkIcon"
                      color="primary"
                      fontSize="small"
                      sx={{ marginTop: convertPxToRem(4) }}
                    ></IconLoader>
                  </ListItemIcon>
                  <Stack
                    flexDirection="column"
                    alignItems="flex-start"
                    spacing={0.5}
                  >
                    <ListItemText
                      color="neutral.main"
                      primary={
                        <Typography
                          variant="body2"
                          sx={{ fontSize: convertPxToRem(14) }}
                        >
                          {text}
                        </Typography>
                      }
                    />
                  </Stack>
                </ListItem>
                {index < riskList.length - 1 && <Divider />}
              </>
            ))}
        </Stack>
      </Stack>
    </DefaultAccordion>
  );
}
