import { Divider, Stack, Typography } from "@mui/material";
import PathologyOrderSummary from ".";
import MaxWidthContainer from "../../../Common/components/MaxWidthContainer";
import {
  PathologyOrderStatus,
  PathologyPanelChargeCode,
} from "@/services/core-api-adapter";

export default function PathologyOrderSummaryExamples() {
  const pendingData = {
    orderId: "1",
    panelName: "STD Screening (excl HIV)",
    panelChargeCode: PathologyPanelChargeCode.STD_SCREENING_EXCL_HIV,
    orderNumber: "12345",
    orderUnuFeeAmount: 0,
    orderPrice: 100,
    created: "2021-10-10T10:00:00Z",
    orderPriceFormatted: "R100",
    status: PathologyOrderStatus.PENDING,
    orderPathologyTests: [
      {
        testName: "STD PCR",
        testAmount: 0,
        testCode: "1234",
      },
      {
        testName: "Hep B surface antigen",
        testAmount: 0,
        testCode: "1234",
      },
      {
        testName: "Syphilis RPR + T Pallidum ELISA",
        testAmount: 0,
        testCode: "1234",
      },
    ],
  };

  const completedData = {
    orderId: "1",
    panelName: "General Screening - Male over 45",
    panelChargeCode: PathologyPanelChargeCode.MALE_OVER_45,
    orderUnuFeeAmount: 0,
    orderNumber: "12345",
    orderPrice: 100,
    created: "2021-10-10T10:00:00Z",
    orderPriceFormatted: "R100",
    status: PathologyOrderStatus.COMPLETED,
    orderPathologyTests: [
      {
        testName: "STD PCR",
        testAmount: 0,
        testCode: "1234",
      },
      {
        testName: "Hep B surface antigen",
        testAmount: 0,
        testCode: "1234",
      },
      {
        testName: "Syphilis RPR + T Pallidum ELISA",
        testAmount: 0,
        testCode: "1234",
      },
    ],
  };

  const noOrderNumberData = {
    orderId: "1",
    panelName: "STD Screening (excl HIV)",
    panelChargeCode: PathologyPanelChargeCode.STD_SCREENING_EXCL_HIV,
    orderUnuFeeAmount: 0,
    orderNumber: "",
    orderPrice: 100,
    created: "2021-10-10T10:00:00Z",
    orderPriceFormatted: "R100",
    status: PathologyOrderStatus.CONFIRMED,
    orderPathologyTests: [
      {
        testName: "STD PCR",
        testAmount: 0,
        testCode: "1234",
      },
      {
        testName: "Hep B surface antigen",
        testAmount: 0,
        testCode: "1234",
      },
      {
        testName: "Syphilis RPR + T Pallidum ELISA",
        testAmount: 0,
        testCode: "1234",
      },
    ],
  };

  return (
    <MaxWidthContainer>
      <Stack spacing={2}>
        <Typography variant="h3" fontWeight="bold" textAlign="center" mb={2}>
          Pathology Order Summary (Not pending)
        </Typography>
        <Stack mb={3}>
          <PathologyOrderSummary
            onViewResultsClick={() => {
              console.log("on View results clicked");
            }}
            order={completedData}
            onViewReceiptClick={() => {
              console.log("Payment receipt clicked");
            }}
            onBackButtonClick={() => {
              console.log("Back button clicked");
            }}
            onHowToPrepareClick={() => {
              console.log("How to prepare clicked");
            }}
          />
        </Stack>
        <Divider />
        <Typography variant="h3" fontWeight="bold" textAlign="center" mb={2}>
          Pathology Order Summary (pending)
        </Typography>
        <Stack>
          <PathologyOrderSummary
            onViewResultsClick={() => {
              console.log("on View results clicked");
            }}
            order={pendingData}
            onViewReceiptClick={() => {
              console.log("Payment receipt clicked");
            }}
            onBackButtonClick={() => {
              console.log("Back button clicked");
            }}
            onHowToPrepareClick={() => {
              console.log("How to prepare clicked");
            }}
          />
        </Stack>

        <Divider />

        <Typography variant="h3" fontWeight="bold" textAlign="center" mb={2}>
          Pathology Order Summary without order number
        </Typography>
        <Stack mb={3}>
          <PathologyOrderSummary
            onViewResultsClick={() => {
              console.log("on View results clicked");
            }}
            order={noOrderNumberData}
            onViewReceiptClick={() => {
              console.log("Payment receipt clicked");
            }}
            onBackButtonClick={() => {
              console.log("Back button clicked");
            }}
            onHowToPrepareClick={() => {
              console.log("How to prepare clicked");
            }}
          />
        </Stack>
      </Stack>
    </MaxWidthContainer>
  );
}
