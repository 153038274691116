import { useEffect, useState } from "react";
import { useMachine } from "@xstate/react";
import { eventNames, myProfileFlowStateMachine } from "./machine";
import MyProfile from "../MyProfile";
import { MembershipDependent } from "@/services/core-api-adapter";
import { Stack, Typography } from "@mui/material";
import DefaultError from "@/features/Common/components/DefaultError";
import { t } from "i18next";
import { Navigate } from "react-router";
import { ConfirmDependentRemoval } from "@/features/Membership/components/ConfirmDependentRemoval";
import FullscreenLoadingIndicator from "@/features/Common/components/FullscreenLoadingIndicator";
import UpdateDependentsThankYou from "../UpdateDependentsThankYou";
import AddDependentsForm from "../AddDependentsForm";

export default function MyProfileFlow() {
  const [stateMachineState, dispatchStateMachineEvent] = useMachine(
    myProfileFlowStateMachine
  );
  const [isAttemptingRemoval, setIsAttemptingRemoval] =
    useState<boolean>(false);
  const [isDependentRemovalModalOpen, setIsDependentRemovalModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    setIsAttemptingRemoval(
      stateMachineState.matches("deletingDependentDetails")
    );
  }, [isAttemptingRemoval, stateMachineState]);

  function calculateRemainingAllowedDependents() {
    const addedDependents = stateMachineState.context?.getDependentsResponse;

    if (addedDependents && Array.isArray(addedDependents)) {
      return (
        stateMachineState.context?.maxAllowedDependents -
        addedDependents?.length
      );
    }

    return stateMachineState.context?.maxAllowedDependents;
  }

  function onAddChildDependentButtonClick() {
    dispatchStateMachineEvent({
      type: eventNames.ADD_PEOPLE_BUTTON_CLICKED,
    });
  }

  function findExistingDependent() {
    const dependents = stateMachineState.context?.getDependentsResponse;
    const activeDependent = stateMachineState.context?.activeDependent;

    const foundDependent =
      dependents?.find(
        (dep: any) =>
          dep?.identityDocumentValue === activeDependent?.identityDocumentValue
      ) || null;

    return foundDependent;
  }

  function onAddDependentsCompletedFlow(dependent: MembershipDependent) {
    dispatchStateMachineEvent({
      type: eventNames.CONTINUE_BUTTON_CLICKED,
      input: dependent,
    });
  }

  function onEditChildDependentButtonClick(dependent: MembershipDependent) {
    dispatchStateMachineEvent({
      type: eventNames.EDIT_DEPENDENT_BUTTON_CLICKED,
      input: dependent,
    });
  }

  function onBackButtonClick() {
    dispatchStateMachineEvent({ type: eventNames.BACK_BUTTON_CLICKED });
  }

  function onContinueButtonClick() {
    dispatchStateMachineEvent({ type: eventNames.CONTINUE_BUTTON_CLICKED });
  }

  function onDependentsOverviewConfirmDependentRemoval() {
    setIsAttemptingRemoval(true);
    dispatchStateMachineEvent({
      type: eventNames.CONFIRM_DELETE_DEPENDENT_BUTTON_CLICKED,
      input: stateMachineState.context?.activeDependent,
    });
  }

  function onModalClose() {
    dispatchStateMachineEvent({ type: eventNames.CLOSE_BUTTON_CLICKED });
    setIsDependentRemovalModalOpen(false);
  }

  function onDeleteChildDependentButtonClick(dependent: MembershipDependent) {
    setIsDependentRemovalModalOpen(true);
    dispatchStateMachineEvent({
      type: eventNames.DELETE_DEPENDENT_BUTTON_CLICKED,
      input: dependent,
    });
  }

  return (
    <>
      {(stateMachineState.matches("fetchingDependents") ||
        stateMachineState.matches("submittingDependentDetails") ||
        stateMachineState.matches("updatingMembershipDependents")) && (
        <FullscreenLoadingIndicator />
      )}

      {(stateMachineState.matches("showingProfile") ||
        stateMachineState.matches("error") ||
        stateMachineState.matches("confirmingDependentRemoval") ||
        stateMachineState.matches("deleteDependentError") ||
        stateMachineState.matches("deleteDependentSuccess") ||
        stateMachineState.matches("deletingDependentDetails")) && (
        <MyProfile
          dependentsList={stateMachineState.context.getDependentsResponse}
          remainingDependentsCount={calculateRemainingAllowedDependents()}
          maxDependentAge={stateMachineState.context.maxDependentAge}
          onAddChildDependentButtonClick={onAddChildDependentButtonClick}
          onEditChildDependentButtonClick={onEditChildDependentButtonClick}
          onDeleteChildDependentButtonClick={onDeleteChildDependentButtonClick}
          isError={stateMachineState.matches("error")}
        />
      )}

      {(stateMachineState.matches("collectingDependentDetails") ||
        stateMachineState.matches("editingDependentDetails")) && (
        <Stack minHeight="100%" p={2}>
          <AddDependentsForm
            onBackButtonClick={onBackButtonClick}
            selectedDependent={
              findExistingDependent() ||
              stateMachineState.context.activeDependent
            }
            onCompletedFlow={onAddDependentsCompletedFlow}
          />
        </Stack>
      )}

      {(stateMachineState.matches("confirmingDependentRemoval") ||
        stateMachineState.matches("deleteDependentError") ||
        stateMachineState.matches("deleteDependentSuccess") ||
        stateMachineState.matches("deletingDependentDetails")) && (
        <ConfirmDependentRemoval
          dependent={stateMachineState.context.activeDependent as any}
          onDependentRemoval={onDependentsOverviewConfirmDependentRemoval}
          confirmingRemoval={
            isDependentRemovalModalOpen && !isAttemptingRemoval
          }
          attemptingRemoval={isAttemptingRemoval}
          removalError={stateMachineState.matches("deleteDependentError")}
          removalSuccess={stateMachineState.matches("deleteDependentSuccess")}
          shouldModalOpen={isDependentRemovalModalOpen}
          onModalClose={onModalClose}
        />
      )}

      {stateMachineState.matches("thankYou") && (
        <UpdateDependentsThankYou
          onDone={onContinueButtonClick}
          translationKey="MyProfile.dependants.thankYou.text"
        />
      )}

      {stateMachineState.matches("fullscreenError") && (
        <Stack height="100vh" justifyContent="end">
          <DefaultError onComeBackLater={onContinueButtonClick}>
            <Typography variant="h2">{t("GenericError.title")}</Typography>
            <Typography pb={2}>{t("GenericError.content")}</Typography>
          </DefaultError>
        </Stack>
      )}

      {stateMachineState.matches("exit") && <Navigate to="/"></Navigate>}
    </>
  );
}
