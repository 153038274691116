import { useMachine } from "@xstate/react";
import { UnsubscribeFlowMachine } from "./machine";
import UnsubscribeForm from "../UnsubscribeForm";
import { Navigate, useSearchParams } from "react-router-dom";
import FullscreenLoadingIndicator from "@/features/Common/components/FullscreenLoadingIndicator";
import UnsubscribeSuccess from "../UnsubscribeSuccess";
import { Stack, Typography } from "@mui/material";
import DefaultError from "@/features/Common/components/DefaultError";
import { useTranslation } from "react-i18next";

export default function UnsubscribeFlow() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const memberId = searchParams.get("memberId");
  const [machineState, machineDispatch] = useMachine(UnsubscribeFlowMachine, {
    input: {
      context: {
        memberId,
      },
    },
  });

  function handleFormSubmit(reason: string) {
    machineDispatch({ type: "SUBMIT_BUTTON_CLICKED", input: { reason } });
  }

  return (
    <>
      {machineState.matches("submittingUnsubscribeDetails") && (
        <FullscreenLoadingIndicator />
      )}

      {machineState.matches("collectingUnsubscribeDetails") && (
        <UnsubscribeForm onFormSubmit={handleFormSubmit} />
      )}

      {machineState.matches("success") && (
        <UnsubscribeSuccess
          onDoneClick={() => machineDispatch({ type: "DONE_BUTTON_CLICKED" })}
        />
      )}

      {machineState.matches("exit") && <Navigate to="/" />}

      {machineState.matches("error") && (
        <Stack height="100vh" justifyContent="end">
          <DefaultError
            onComeBackLater={() =>
              machineDispatch({ type: "COME_BACK_LATER_BUTTON_CLICKED" })
            }
            onTryAgain={() =>
              machineDispatch({ type: "TRY_AGAIN_BUTTON_CLICKED" })
            }
          >
            <Typography variant="h2">{t("GenericError.title")}</Typography>
            <Typography pb={2}>{t("GenericError.content")}</Typography>
          </DefaultError>
        </Stack>
      )}
    </>
  );
}
