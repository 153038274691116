import { Box } from "@mui/material";
import AddDependentsForm from ".";

export default function AddDependentsFormExamples() {
  function onCompletedFlow() {
    console.log("onCompletedFlow");
  }

  return (
    <Box sx={{ p: 2 }}>
      <AddDependentsForm onCompletedFlow={onCompletedFlow} />
    </Box>
  );
}
