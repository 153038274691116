import { emailOptOut } from "@/services/core-api-adapter";
import { setup, assign, fromPromise } from "xstate";

interface Context {
  memberId: string | null;
  reason: string | null;
}

export const eventNames = {
  SUBMIT_BUTTON_CLICKED: "SUBMIT_BUTTON_CLICKED",
  DONE_BUTTON_CLICKED: "DONE_BUTTON_CLICKED",
  COME_BACK_LATER_BUTTON_CLICKED: "DONE_BUTTON_CLICKED",
  TRY_AGAIN_BUTTON_CLICKED: "TRY_AGAIN_BUTTON_CLICKED",
};

const initialContextValues: Context = {
  memberId: null,
  reason: null,
};

export const UnsubscribeFlowMachine = setup({
  types: {
    context: {} as Context,
    events: {} as any,
  },
  actions: {
    addReasonToContext: assign(({ event }) => {
      return {
        reason: event.input.reason,
      };
    }),
  },
  guards: {
    memberIdIsProvided: ({ context }) => {
      return (
        context.memberId !== null &&
        context.memberId !== undefined &&
        context.memberId !== ""
      );
    },
  },
  actors: {
    submitUnsubscribeDetails: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        return await emailOptOut({
          memberId: input.context.memberId || "",
          reason: input.context.reason || "",
        });
      }
    ),
  },
}).createMachine({
  context: initialContextValues,
  id: "UnsubscribeFlow",
  initial: "collectingUnsubscribeDetailsOrExiting",
  states: {
    collectingUnsubscribeDetailsOrExiting: {
      entry: assign(({ event }) => {
        const entryContext = { ...initialContextValues };
        const { memberId } = event.input?.context ? event.input.context : "";

        return {
          ...entryContext,
          memberId,
        };
      }),
      always: [
        {
          target: "collectingUnsubscribeDetails",
          guard: "memberIdIsProvided",
        },
        {
          target: "exit",
        },
      ],
    },
    collectingUnsubscribeDetails: {
      on: {
        SUBMIT_BUTTON_CLICKED: {
          target: "submittingUnsubscribeDetails",
          actions: "addReasonToContext",
        },
      },
    },
    submittingUnsubscribeDetails: {
      invoke: {
        id: "submittingUnsubscribeDetails",
        src: "submitUnsubscribeDetails",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "success",
        },
        onError: {
          target: "error",
        },
      },
    },
    success: {
      on: {
        DONE_BUTTON_CLICKED: {
          target: "exit",
        },
      },
    },
    error: {
      on: {
        TRY_AGAIN_BUTTON_CLICKED: {
          target: "collectingUnsubscribeDetails",
        },
        COME_BACK_LATER_BUTTON_CLICKED: {
          target: "exit",
        },
      },
    },
    exit: { type: "final" },
  },
});
