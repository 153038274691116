import {
  convertPxToRem,
  formatDateToReadableString,
  formatDateToReadableTime,
  getShortDayFromDate,
  getTimeZoneFromDate,
} from "@/utils";
import Panel from "../Panel";
import IconLoader from "../IconLoader";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import theme, { defaultFullscreenPageStyling } from "@/theme";
import CircledBackButton from "../CircledBackButton";

interface PaymentReceiptProps {
  amountFormatted: string;
  transactionDate: string;
  referenceNumber: string;
  merchantId: string;
  cardNumber: string;
  onBackButtonClick: () => void;
}

export default function PaymentReceipt({
  amountFormatted,
  transactionDate,
  referenceNumber,
  merchantId,
  cardNumber,
  onBackButtonClick,
}: PaymentReceiptProps) {
  const { t } = useTranslation();

  const formattedTransactionDate = `${getShortDayFromDate(new Date(transactionDate))}, ${formatDateToReadableString(new Date(transactionDate))}`;
  const formattedTransactionTime = formatDateToReadableTime(transactionDate);
  const formattedTransactionTimeZone = getTimeZoneFromDate(
    new Date("2024-10-15T14:45:00+02:00")
  );

  return (
    <Stack spacing={5} sx={{ ...defaultFullscreenPageStyling }}>
      <CircledBackButton showLabel onClick={onBackButtonClick} />
      <Panel sx={{ paddingBottom: 4 }}>
        <Stack justifyContent="center" alignItems="center" spacing={4}>
          <IconLoader
            icon="UnuLogo"
            color="primary"
            sx={{ fontSize: convertPxToRem(90) }}
          />
          <Stack
            direction="row"
            width="80%"
            justifyContent="space-between"
            color="neutral.main"
          >
            <Typography variant="subtitle1" color="inherit" fontWeight={400}>
              {formattedTransactionDate}
            </Typography>
            <Divider
              sx={{ borderColor: theme.palette.neutral.main }}
              orientation="vertical"
              flexItem
            />
            <Typography variant="subtitle1" color="inherit" fontWeight={400}>
              {formattedTransactionTime}
            </Typography>
            <Divider
              sx={{ borderColor: theme.palette.neutral.main }}
              orientation="vertical"
              flexItem
            />
            <Typography variant="subtitle1" color="inherit" fontWeight={400}>
              {formattedTransactionTimeZone}
            </Typography>
          </Stack>
          <Divider sx={{ width: "100%" }} />
          <Stack alignItems="center">
            <IconLoader
              icon="TrolleyWithCheckIcon"
              color="primary"
              sx={{ fontSize: convertPxToRem(90) }}
            />
            <Typography variant="h2">{t("PaymentReceipt.title")}</Typography>
            <Typography variant="h2" color="primary">
              {amountFormatted}
            </Typography>
          </Stack>
          <Stack textAlign="left" width="100%" spacing={2}>
            <Box>
              <Typography variant="subtitle2">
                {t("PaymentReceipt.referenceNumber.title")}
              </Typography>
              <Typography variant="h4">{referenceNumber}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                {t("PaymentReceipt.merchantId.title")}
              </Typography>
              <Typography variant="h4">{merchantId}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                {t("PaymentReceipt.cardNumber.title")}
              </Typography>
              <Typography variant="h4">{cardNumber}</Typography>
            </Box>
          </Stack>
          <Divider sx={{ width: "100%" }} />
          <Typography variant="subtitle1" color="neutral.main">
            {t("PaymentReceipt.footer.title")}
          </Typography>
        </Stack>
      </Panel>
    </Stack>
  );
}
