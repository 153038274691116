import CircledBackButton from "@/features/Common/components/CircledBackButton";
import { trackPageViewV2 } from "@/services/analytics-adapter";
import theme, { defaultFullscreenPageStyling, shadows } from "@/theme";
import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface PathologyPreparePromptProps {
  onBackButtonClick: () => void;
}

export function PathologyPreparePrompt({
  onBackButtonClick,
}: PathologyPreparePromptProps) {
  const { t } = useTranslation();

  useEffect(() => {
    trackPageViewV2({
      pageName: "Pathology:How to prepare for blood test",
      pageSubSection1: "Pathology",
      pageSubSection2: "Pathology:How to prepare for blood test",
      pageCategory: "Pathology",
    });
  }, []);

  return (
    <Stack
      minHeight="100vh"
      p={2}
      spacing={3}
      sx={{ ...defaultFullscreenPageStyling }}
    >
      <CircledBackButton onClick={onBackButtonClick} showLabel />

      <Typography variant="h2" textAlign="center">
        {t("PathologyPreparePrompt.title")}
      </Typography>

      <Stack
        bgcolor="neutral.50"
        borderRadius={theme.shape.borderRadius}
        boxShadow={shadows.light}
        p={4}
      >
        <Stack spacing={2}>
          <Stack direction="row" gap={2}>
            <Typography variant="body1" lineHeight={1.3}>
              {t("PathologyPreparePrompt.body")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
