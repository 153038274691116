import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";
import IconLoader from "@/features/Common/components/IconLoader";
import {
  AnalyticsEvent,
  trackFormEvent,
  trackPageViewV2,
} from "@/services/analytics-adapter";
import theme from "@/theme";
import {
  Box,
  Checkbox,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

interface UnsubscribeFormProps {
  onFormSubmit: (reason: string) => void;
}

export default function UnsubscribeForm({
  onFormSubmit,
}: UnsubscribeFormProps) {
  const { t } = useTranslation();
  const [isFormValid, setIsFormValid] = useState(false);
  const [isOtherReasonSelected, setIsOtherReasonSelected] = useState(false);
  const [selectedReason, setSelectedReason] = useState<string | null>(null);

  function onCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    if (value.toLowerCase() === "other") {
      if (!isOtherReasonSelected) {
        setIsOtherReasonSelected(true);
        setSelectedReason(null);
      }
    } else {
      setIsOtherReasonSelected(false);
      setSelectedReason(value);
    }
  }

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (isFormValid && selectedReason) {
      onFormSubmit(selectedReason);
      trackFormEvent(AnalyticsEvent.FORM_COMPLETE, {
        formName: "Unsubscribe",
      });
    }
  }

  useEffect(() => {
    setIsFormValid(selectedReason !== null && selectedReason !== "");
  }, [selectedReason]);

  useEffect(() => {
    trackPageViewV2({
      pageName: "Unsubscribe:Unsubscribe Form",
      pageSubSection1: "Unsubscribe",
      pageSubSection2: "Unsubscribe:Unsubscribe Form",
      pageCategory: "Unsubscribe",
    });

    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Unsubscribe",
    });
  }, []);

  return (
    <Stack
      p={2}
      mt={3}
      spacing={6}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      component="form"
      onSubmit={onSubmit}
    >
      <Stack spacing={2}>
        <IconLoader
          icon="FrowningFaceIcon"
          color="secondary"
          sx={{
            fontSize: theme.spacing(10),
            alignSelf: "center",
          }}
        />
        <Typography variant="h2">{t("Unsubscribe.title")}</Typography>
      </Stack>

      <Stack spacing={2}>
        {(
          t<any, any, string[]>("Unsubscribe.body", {
            returnObjects: true,
          }) as []
        ).map((text: string, index: number) => (
          <Trans key={String(text + index)}>
            <Typography variant="h4" fontWeight={100}>
              {text}
            </Typography>
          </Trans>
        ))}
      </Stack>

      <Stack spacing={2} width="80%">
        <Divider />
      </Stack>

      <Stack spacing={3}>
        <Typography variant="h3" fontWeight={600}>
          {t("Unsubscribe.reason.title")}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          textAlign="left"
          flexWrap="wrap"
        >
          {(
            t<any, any, string[]>("Unsubscribe.reasons.options", {
              returnObjects: true,
            }) as []
          ).map((text: string, index: number) => (
            <Stack key={String(text + index)} direction="row" p={1} spacing={2}>
              <Checkbox
                key={String(text + index)}
                inputProps={{
                  "aria-label": text,
                }}
                sx={{ padding: 0 }}
                value={text}
                checked={selectedReason === text && !isOtherReasonSelected}
                onChange={onCheckboxChange}
              />
              <Typography>{text}</Typography>
            </Stack>
          ))}

          <Stack direction="row" spacing={2} p={1}>
            <Checkbox
              inputProps={{
                "aria-label": t("Unsubscribe.reasons.other"),
              }}
              sx={{ padding: 0 }}
              value={t("Unsubscribe.reasons.other")}
              checked={isOtherReasonSelected}
              onChange={onCheckboxChange}
            />
            <Typography>{t("Unsubscribe.reasons.other")}</Typography>
          </Stack>
        </Stack>

        {isOtherReasonSelected && (
          <Stack width="100%" spacing={1}>
            <Typography
              variant="h5"
              textAlign="left"
              color={isFormValid ? "neutral.main" : "error"}
            >
              {t("Unsubscribe.reasons.other.input.label")}
            </Typography>
            <TextField
              multiline={true}
              aria-label={t("Unsubscribe.reasons.other.input.label")}
              value={selectedReason ?? ""}
              minRows={5}
              onChange={(event) => setSelectedReason(event.target.value)}
            />
            {!isFormValid && (
              <Typography variant="h5" textAlign="left" color="error">
                {t("Unsubscribe.reason.error")}
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
      <Box>
        <ButtonWithAnalytics
          text={t("Unsubscribe.CTA.title")}
          page="Unsubscribe"
          intent="confirmational"
          type="submit"
          disabled={!isFormValid}
          endIcon={<IconLoader icon="ChevronNextIcon" />}
        >
          {t("Unsubscribe.CTA.title")}
        </ButtonWithAnalytics>
      </Box>
    </Stack>
  );
}
