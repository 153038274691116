import BottomNavigation from "@/features/Common/components/BottomNavigation";
import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";
import IconLoader from "@/features/Common/components/IconLoader";
import MembershipCardModal from "@/features/Common/components/MembershipCardModal";
import Panel from "@/features/Common/components/Panel";
import useGetMemberships from "@/features/Common/hooks/useGetMemberships";
import { trackEvent, trackUserInteraction } from "@/services/analytics-adapter";
import { getConfigurationVariable } from "@/services/configuration-adapter";
import {
  findDependentsInMembership,
  logout,
} from "@/services/core-api-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "@/services/feature-toggle-adapter";
import { useGlobalStore } from "@/store";
import {
  defaultFullscreenPageStyling,
  overrideDefaultLinkStyling,
  pageWithBottomNavigationStyling,
} from "@/theme";
import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

export default function More() {
  const { t } = useTranslation();
  const { state, dispatch } = useGlobalStore();
  const navigate = useNavigate();

  const isThirdPartyMember = state.isThirdPartyMember;
  const isRetailMember = state.currentUser.isRetailMember;

  const isSensUpdatesEnabled = isFeatureEnabled(
    FeatureToggleIdentifier.ENABLE_SENS_UPDATES
  );

  const [dependantsCount, setDependantsCount] = useState(0);

  const { firstMembership, isMembershipsLoading, membershipsFetchError } =
    useGetMemberships();

  useEffect(() => {
    if (firstMembership && !isMembershipsLoading && !membershipsFetchError) {
      setDependantsCount(findDependentsInMembership(firstMembership).length);
    }
  }, [firstMembership]);

  const [isMembershipCardModalOpen, setIsMembershipCardModalOpen] =
    useState(false);

  const onLogoutButtonClick = () => {
    logout().then(() => {
      dispatch({
        type: "CLEAR_CURRENT_USER",
      });
      navigate("/");
    });
  };

  function onFAQLinkClick() {
    trackUserInteraction({
      linkText: `More | ${t("More.helpSection.faqLink")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
    trackEvent({ event: "action.faqVisited", source: "more screen" });
  }

  function onGetSupportLinkClick() {
    trackUserInteraction({
      linkText: `More | ${t("More.helpSection.supportLink")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
    trackEvent({ event: "action.supportRequested", source: "more screen" });
  }

  function onContactDetailsLinkClick() {
    trackUserInteraction({
      linkText: `More | ${t("More.generalSettingsSection.contactDetailsLink")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });

    trackEvent({
      event: "action.contactDetailsClicked",
      source: "more screen",
    });
  }

  function onAboutAppLinkClick() {
    trackUserInteraction({
      linkText: `More | ${t("More.helpSection.aboutAppLink")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });

    trackEvent({ event: "action.aboutAppClicked", source: "more screen" });
  }

  function onCommunicationSettingsLinkClick() {
    trackUserInteraction({
      linkText: `More | ${t("More.generalSettingsSection.communicationPreferencesLink")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });

    trackEvent({
      event: "action.communicationPreferencesClicked",
      source: "More screen",
    });
  }

  function onViewMyMembershipCardClick() {
    trackUserInteraction({
      linkText: `More | ${t("More.myMembershipSection.membershipCardLink")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });

    trackEvent({
      event: "action.membershipCardClicked",
      source: "more screen",
    });

    setIsMembershipCardModalOpen(true);
  }

  function onViewMyHealthProfileLinkClick() {
    trackUserInteraction({
      linkText: `More | ${t("More.generalSettingsSection.healthProfileLink")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });

    trackEvent({ event: "action.healthProfileClicked", source: "more screen" });
  }

  function onViewMyPurchasesLinkClick() {
    trackUserInteraction({
      linkText: `More | ${t("More.myPurchases.purchasesLink")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });

    trackEvent({ event: "action.myPurchasesClicked", source: "more screen" });
  }

  function onMyProfileLinkClick() {
    trackUserInteraction({
      linkText: `More | ${t("common.myProfile")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });

    trackEvent({ event: "action.myProfileClicked", source: "more screen" });
  }

  function onMembershipCardModalClose() {
    setIsMembershipCardModalOpen(false);
  }

  return (
    <Stack
      sx={{
        ...defaultFullscreenPageStyling,
        ...pageWithBottomNavigationStyling,
      }}
    >
      <Typography
        component="h1"
        variant="h2"
        sx={{
          mb: 1,
        }}
      >
        {t("More.title")}
      </Typography>
      <Typography
        variant="body1"
        color="neutral"
        sx={{
          mb: 4,
        }}
      >
        {t("More.subtitle")}
      </Typography>

      <Stack spacing={4}>
        {isSensUpdatesEnabled && isRetailMember ? (
          <Stack spacing={2}>
            <Typography component="h2">{t("common.myProfile")}</Typography>
            <Panel padding={0}>
              <Stack
                direction="row"
                alignItems="center"
                component={Link}
                to={"/home/my-profile"}
                aria-label={t("common.myProfile")}
                p={2}
                sx={{
                  ...overrideDefaultLinkStyling,
                }}
                justifyContent="space-between"
                onClick={onMyProfileLinkClick}
              >
                <Stack>
                  <Typography component="p" variant="h4">
                    {state.currentUser.firstName} {state.currentUser.lastName}
                  </Typography>
                  {firstMembership &&
                    !isMembershipsLoading &&
                    !membershipsFetchError && (
                      <>
                        {dependantsCount >= 1 ? (
                          <Typography component="p" variant="body2">
                            {t("More.myProfile.dependants", {
                              count: dependantsCount,
                            })}
                          </Typography>
                        ) : (
                          <Typography component="p" variant="body2">
                            {t("More.myProfile.dependants_none")}
                          </Typography>
                        )}
                      </>
                    )}
                </Stack>

                <Stack display="flex" flexDirection="row" alignItems="center">
                  <IconLoader icon="ChevronNextIcon" />
                </Stack>
              </Stack>
            </Panel>
          </Stack>
        ) : (
          <Panel>
            <Typography>{state.currentUser.firstName}</Typography>
            <ButtonWithAnalytics
              page="More"
              text={t("common.logOutButton")}
              intent="confirmational"
              onClick={() => onLogoutButtonClick()}
              variant="text"
              color="primary"
              size="large"
              sx={{
                fontWeight: 700,
                minWidth: "auto",
                mt: 1,
                p: 0,
                textAlign: "left",
              }}
            >
              {t("common.logOutButton")}
            </ButtonWithAnalytics>
          </Panel>
        )}

        {!isThirdPartyMember && !isRetailMember && (
          <Stack spacing={2}>
            <Typography component="h2">
              {t("More.myMembershipSection.title")}
            </Typography>
            <Panel padding={0}>
              <Stack
                direction="row"
                alignItems="center"
                component={Link}
                to={""}
                p={2}
                sx={{
                  ...overrideDefaultLinkStyling,
                }}
                justifyContent="space-between"
                onClick={onViewMyMembershipCardClick}
              >
                <Typography component="p" variant="h4">
                  {t("More.myMembershipSection.membershipCardLink")}
                </Typography>
                <IconLoader icon="ChevronNextIcon" />
              </Stack>
            </Panel>
          </Stack>
        )}

        {!isThirdPartyMember && isRetailMember && !isSensUpdatesEnabled && (
          <Stack spacing={2}>
            <Typography component="h2">
              {t("More.myMembershipSection.title")}
            </Typography>
            <Panel padding={0}>
              <Stack
                direction="row"
                alignItems="center"
                component={Link}
                to={""}
                p={2}
                sx={{
                  ...overrideDefaultLinkStyling,
                }}
                justifyContent="space-between"
                onClick={onViewMyMembershipCardClick}
              >
                <Typography component="p" variant="h4">
                  {t("More.myMembershipSection.membershipCardLink")}
                </Typography>
                <IconLoader icon="ChevronNextIcon" />
              </Stack>
            </Panel>
          </Stack>
        )}

        <Stack spacing={2}>
          <Typography component="h2">
            {t("More.generalSettingsSection.title")}
          </Typography>
          <Panel padding={0}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              component={Link}
              onClick={onContactDetailsLinkClick}
              to={"/home/my-health/contact-details"}
              p={2}
              sx={{ ...overrideDefaultLinkStyling }}
            >
              <Typography component="p" variant="h4">
                {t("More.generalSettingsSection.contactDetailsLink")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" />
            </Stack>
          </Panel>

          <Panel padding={0}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              component={Link}
              onClick={onCommunicationSettingsLinkClick}
              to={"/home/my-health/communication-preferences"}
              p={2}
              sx={{ ...overrideDefaultLinkStyling }}
            >
              <Typography component="h2" variant="h4">
                {t("More.generalSettingsSection.communicationPreferencesLink")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" />
            </Stack>
          </Panel>

          {isRetailMember && !isSensUpdatesEnabled && (
            <Panel padding={0}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                component={Link}
                onClick={onViewMyHealthProfileLinkClick}
                to={"/home/my-health/health-profile"}
                p={2}
                sx={{ ...overrideDefaultLinkStyling }}
              >
                <Typography component="p" variant="h4">
                  {t("More.generalSettingsSection.healthProfileLink")}
                </Typography>
                <IconLoader icon="ChevronNextIcon" />
              </Stack>
            </Panel>
          )}
        </Stack>

        {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_PATHOLOGY_PANELS) && (
          <Stack spacing={2}>
            <Typography component="h2">
              {t("More.myPurchases.title")}
            </Typography>
            <Panel padding={0}>
              <Stack
                direction="row"
                alignItems="center"
                component={Link}
                to={"/home/more/my-purchases"}
                aria-label={t("More.myPurchases.purchasesLink")}
                p={2}
                sx={{
                  ...overrideDefaultLinkStyling,
                }}
                justifyContent="space-between"
                onClick={onViewMyPurchasesLinkClick}
              >
                <Typography component="p" variant="h4">
                  {t("More.myPurchases.purchasesLink")}
                </Typography>

                <Stack display="flex" flexDirection="row" alignItems="center">
                  <IconLoader icon="ChevronNextIcon" />
                </Stack>
              </Stack>
            </Panel>
          </Stack>
        )}

        <Stack spacing={2}>
          <Typography component="h2">{t("More.helpSection.title")}</Typography>
          <Panel padding={0}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              component="a"
              href={getConfigurationVariable("VITE_APP_UNU_HEALTH_FAQ_URI")}
              onClick={onFAQLinkClick}
              target="_blank"
              p={2}
              sx={{ ...overrideDefaultLinkStyling }}
            >
              <Typography component="p" variant="h4">
                {t("More.helpSection.faqLink")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" />
            </Stack>
          </Panel>
          <Panel padding={0}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              component={Link}
              to={"/home/more/get-support"}
              onClick={onGetSupportLinkClick}
              p={2}
              sx={{ ...overrideDefaultLinkStyling }}
            >
              <Typography component="p" variant="h4">
                {t("More.helpSection.supportLink")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" />
            </Stack>
          </Panel>
          <Panel padding={0}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              component={Link}
              onClick={onAboutAppLinkClick}
              to={"/home/help/about-app"}
              p={2}
              sx={{ ...overrideDefaultLinkStyling }}
            >
              <Typography component="p" variant="h4">
                {t("More.helpSection.aboutAppLink")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" />
            </Stack>
          </Panel>
        </Stack>
      </Stack>

      <BottomNavigation />

      <MembershipCardModal
        isOpen={isMembershipCardModalOpen}
        onClose={onMembershipCardModalClose}
      />
    </Stack>
  );
}
