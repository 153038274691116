import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";
import CircledBackButton from "@/features/Common/components/CircledBackButton";
import Panel from "@/features/Common/components/Panel";
import { trackEvent } from "@/services/analytics-adapter";
import { getConfigurationVariable } from "@/services/configuration-adapter";
import { logout, MembershipDependent } from "@/services/core-api-adapter";
import { useGlobalStore } from "@/store";
import {
  defaultFullscreenPageStyling,
  overrideDefaultLinkStyling,
} from "@/theme";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import DependentsList from "../DependentsList";

interface MyProfileProps {
  dependentsList: MembershipDependent[];
  remainingDependentsCount: number;
  maxDependentAge: number;
  onAddChildDependentButtonClick: () => void;
  onEditChildDependentButtonClick: (dependent: MembershipDependent) => void;
  onDeleteChildDependentButtonClick: (dependent: MembershipDependent) => void;
  isError: boolean;
}

export default function MyProfile({
  dependentsList,
  remainingDependentsCount,
  maxDependentAge,
  onAddChildDependentButtonClick,
  onEditChildDependentButtonClick,
  onDeleteChildDependentButtonClick,
  isError = false,
}: MyProfileProps) {
  const { t } = useTranslation();
  const { state, dispatch } = useGlobalStore();
  const navigate = useNavigate();

  const SUPPORT_VOICE_PHONE_NUMBER_ZA =
    getConfigurationVariable(
      "VITE_APP_UNU_HEALTH_SUPPORT_VOICE_PHONE_NUMBER_ZA"
    ) || "";

  const onLogoutButtonClick = () => {
    logout().then(() => {
      dispatch({
        type: "CLEAR_CURRENT_USER",
      });
      navigate("/");
    });
  };

  const MIN_ALLOWED_DEPENDENTS = 1;

  function onAddDependentButtonHandler() {
    trackEvent({
      event: "action.addDependent",
      source: "My profile",
    });
    onAddChildDependentButtonClick();
  }

  function onEditDependentButtonHandler(dependent: MembershipDependent) {
    trackEvent({
      event: "action.editDependent",
      source: "My profile",
    });
    onEditChildDependentButtonClick(dependent);
  }

  function onDeleteDependentButtonHandler(dependent: MembershipDependent) {
    trackEvent({
      event: "action.deleteDependent",
      source: "My profile",
    });
    onDeleteChildDependentButtonClick(dependent);
  }

  return (
    <Stack sx={{ ...defaultFullscreenPageStyling }} spacing={3}>
      <CircledBackButton showLabel />

      <Typography
        component="h1"
        variant="h2"
        sx={{
          mb: 1,
        }}
      >
        {t("MyProfile.title")}
      </Typography>

      <Panel>
        <Stack spacing={2}>
          <Typography component="p" variant="h4">
            {state.currentUser.firstName} {state.currentUser.lastName}
          </Typography>
          <ButtonWithAnalytics
            page="My profile"
            text={t("common.logOutButton")}
            intent="confirmational"
            onClick={() => onLogoutButtonClick()}
            variant="outlined"
            color="primary"
            size="small"
            fullWidth
          >
            {t("common.logOutButton")}
          </ButtonWithAnalytics>
        </Stack>
      </Panel>

      {Array.isArray(dependentsList) && dependentsList && (
        <Stack spacing={2}>
          <Typography variant="body1" fontWeight="bold">
            {t("MyProfile.dependants.title")}
          </Typography>

          <DependentsList
            dependentsList={dependentsList}
            onEditButtonClick={onEditDependentButtonHandler}
            onDeleteButtonClick={onDeleteDependentButtonHandler}
            isError={isError}
          />
        </Stack>
      )}

      {!isError && (
        <Panel>
          <Stack spacing={2}>
            <ButtonWithAnalytics
              page="My profile"
              text={t("MyProfile.dependants.addDependant.CTA")}
              intent="informational"
              variant="contained"
              size="small"
              fullWidth
              disabled={remainingDependentsCount < MIN_ALLOWED_DEPENDENTS}
              onClick={onAddDependentButtonHandler}
            >
              {t("MyProfile.dependants.addDependant.CTA")}
            </ButtonWithAnalytics>

            <Typography color="neutral.500" textAlign="center" variant="body2">
              {t("MyProfile.dependants.maxDependants", {
                maxDependentAge: maxDependentAge,
              })}
            </Typography>
            <Stack flexDirection="row" justifyContent="space-between">
              <Typography
                color="neutral.500"
                textAlign="center"
                variant="body2"
              >
                {t("common.needHelp")}
              </Typography>
              <Stack
                component={Link}
                sx={{
                  ...overrideDefaultLinkStyling,
                  color: "neutral.dark",
                }}
                to={`tel:${SUPPORT_VOICE_PHONE_NUMBER_ZA}`}
              >
                <Typography variant="body2" color="primary">
                  {SUPPORT_VOICE_PHONE_NUMBER_ZA}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Panel>
      )}
    </Stack>
  );
}
