import { useEffect } from "react";
import { trackPageViewV2 } from "@/services/analytics-adapter";
import MyProfileFlow from "../components/MyProfileFlow";

export default function MyProfilePage() {
  useEffect(() => {
    trackPageViewV2({
      pageName: "My profile:My profile",
      pageSubSection1: "My profile",
      pageSubSection2: "My profile:My profile",
      pageCategory: "My profile",
    });
  }, []);

  return <MyProfileFlow />;
}
